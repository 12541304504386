import axios from "axios";

const basePath = `https://members.rlabtools.org.uk/`;

function get(path: string) {
    return axios.get(`https://members.rlabtools.org.uk/${path}`);
}

function post(path: string, data: any) {
    return axios.post(`https://members.rlabtools.org.uk/${path}`, data);
}

function put(path: string, data: any) {
    return axios.put(`https://members.rlabtools.org.uk/${path}`, data);
}

export {
    get,
    post,
    put,
}