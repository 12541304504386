import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { put } from '../../utils/api';
import { getUser, useFormInput } from '../../utils/common';
import styles from './Signup.module.css';

type Input = {
    name: string,
    text: string,
    type: string,
    required: boolean,
    description?: string,
    inputObject: {
        value: any,
        onChange: (e: any) => void,
    }
};

function mapInputDetailsToElement(input:Input, index?:number): ReactElement<any, any> {
    return <p key={input.name}>
                <label className={styles.formLabel} htmlFor={input.name}>{input.text}</label>
                <input className={styles.formInput} name={input.name} type={input.type} autoFocus={index === 0} required={input.required} id={input.name} {...input.inputObject}/>
                {input.description && <span>{input.description}</span>}
            </p>;
};

const Signup = () => {
    const user = getUser();
    const history = useNavigate();
    
    const [error, setError] = useState<string>();

    const inputsMemberDetails: Input[] = [
        {name: "firstName",text: "First Name", type: "text", required: true, inputObject: useFormInput('')},
        {name: "lastName",text: "Last Name", type: "text", required: true, inputObject: useFormInput('')},
        {name: "email",text: "Email", type: "text", required: true, inputObject: useFormInput(''), description: "This is our primary communication method"},
        {name: "phone1",text: "Phone", type: "tel", required: true, inputObject: useFormInput(''), description: "We need this if there is a need to contact you quickly"},
        {name: "phone2",text: "Phone alt", type: "tel", required: false, inputObject: useFormInput('')},
        {name: "address1",text: "Address Line 1", type: "text", required: true, inputObject: useFormInput(''), description: "We need this for identity confirmation"},
        {name: "address2",text: "Address Line 2", type: "text", required: false, inputObject: useFormInput('')},
        {name: "town",text: "Town / City", type: "text", required: true, inputObject: useFormInput('')},
        {name: "postcode",text: "Postcode", type: "text", required: true, inputObject: useFormInput('')},
        {name: "country",text: "Country", type: "text", required: true, inputObject: useFormInput("United Kingdom")},
    ];

    const elementsMemberDetails = inputsMemberDetails.map(mapInputDetailsToElement);

    const inputGenderSelect = useFormInput('5');
    const inputGuarantee = useFormInput(false);

    const inputsSignupDetails: Input[] = [
        {name: "cardCode",text: "Access Card", type: "text", required: true, inputObject: useFormInput('')},
        {name: "photoId",text: "Photo Id Type", type: "text", required: true, inputObject: useFormInput('')},
        {name: "inductedBy",text: "Inducted BY", type: "text", required: true, inputObject: useFormInput(user?.name), description: "The existing member that gave the new the induction tour"},
        {name: "signupBy",text: "Signed Up By", type: "text", required: true, inputObject: useFormInput(user?.name), description: "The member completing this form"},
    ];

    const elementsSignupDetails = inputsSignupDetails.map(element => mapInputDetailsToElement(element));

    const allInputObjects = [
        ...inputsMemberDetails,
        {inputObject: inputGenderSelect, name: "gender"},
        {inputObject: inputGuarantee, name: "guarantee"},
        ...inputsSignupDetails
    ];

    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        const data: any = {};
        allInputObjects.forEach(o=>data[o.name] = o.inputObject.value)
        setError(undefined);
        put(`member/newMember`, data).then(response => {
            console.log(response);
            history('/dashboard');
        }).catch(error => {
            console.log(error);
            setError("Something went wrong. Please contact the site maintainer or try again later.");
        });
    }
  return (
    <div>
        <h1 className={styles.title}>Sign-up new member</h1>
        <h2 className={styles.subtitle}>Welcome to <strong>rLab</strong>, Reading’s Hackspace (Reading Maker Space Limited)</h2>
        <p>We hope you will enjoy being part of our community and look foward to seeing what you make, mend, re-purpose, create, share, and teach.</p>
        <form className={styles.inputForm} id="form1" name="form1" onSubmit={handleSubmit}>
            <>
            {[
                ...elementsMemberDetails,
                <p key={"gender"}>
                    <label className={styles.formLabel} htmlFor="gender">gender</label>
                    <select className={styles.formInput} id="gender" name="gender" {...inputGenderSelect}>
                        <option value="1">Male</option>
                        <option value="2">Female</option>
                        <option value="3">Trans</option>
                        <option value="4">other</option>
                        <option value="5">Rather not say</option>
                    </select>
                    <span>For diversity monitoring only (optional)</span>
                </p>,

                <label htmlFor="guarantee"  key={"guarantee"}>    
                    <h2>Statement of Guarantee</h2>
                    <p>As we are a non-profit making company, we don't have shareholders, we have ‘members’. To be an official member, which gives you certain voting and procedural rights you need to continue your monthly payments to Reading Maker Space Limited (RMSL), agree to the statement of guarantee below, and make sure your contact details are always up-to-date.<br/><strong> If you do not wish to agree, you can still sign-up but will not be able to vote in RMSL meetings.</strong></p>
                    <input name="guarantee" type="checkbox" id="guarantee" {...inputGuarantee}/>
                    <p><em>I confirm that if the Reading Maker Space Limited / rLAB &nbsp;is wound up while I am a member (or within one  year after I cease to be a member) I will contribute to the assets of the  company  (not exceeding £1) as may be required for:</em></p>,
                    <ul>
                        <li><em>payment of debts and liabilities of the company</em></li>
                        <li><em>payments of costs, charges and expenses of  winding up</em></li>
                        <li><em>adjustment of the rights of the contributors</em></li>
                    </ul>
                </label>,

                <h2 key={"sectionLabel"}>This section is completed by <strong>your rLab inductor</strong></h2>,
                ...elementsSignupDetails,
                <input key={"submit"} className={styles.formSubmit} type="submit" name="submit" id="submit" value="Complete sign up"/>,
            ]}
            </>
        </form>
    </div>
  );
}

export default Signup;