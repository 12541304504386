import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setUserSession, useFormInput } from '../../utils/common';
import styles from './Login.module.css';
import { post } from '../../utils/api';

const Login = () => {
  const history = useNavigate();
  const inputEmail = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState<string>();
  const [resetMessage, setResetMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  // handle button click of login form
  const handleLogin = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if(inputEmail.value === undefined || inputEmail.value === null || inputEmail.value.length < 3){
      setError("Please enter your email address");
      return;
    }
    if(password.value === undefined || password.value === null || password.value.length < 8){
      setError("Please enter your password");
      return;
    }
    setError(undefined);
    setLoading(true);
    post(`users/signin`, { inputEmail: inputEmail.value, password: password.value }).then(response => {
      setLoading(false);
      setUserSession(response.data.token, response.data.user);
      history('/dashboard');
    }).catch(error => {
      setLoading(false);
      if (error?.response?.status === 401) setError(error.response?.data?.message);
      else setError("Something went wrong. Please contact the site maintainer or try again later.");
    });
  }

  const forgotten = () => {
    if(inputEmail.value === undefined || inputEmail.value === null || inputEmail.value.length < 3){
      setResetMessage(true);
      return;
    }
    setLoading(true);
    post(`users/resetpass`, { inputEmail: inputEmail.value }).then( () => {
      setLoading(false);
    }).catch( () => {

      setLoading(false);
    });
  }

  return (
    <>
      <h1 className={styles.title}>rLab member admin</h1>
      <form id="loginForm" name="loginForm" onSubmit={handleLogin}>
        <div className={styles.inputBlock}>
          {resetMessage && (
            <p>Please enter your email below and a password reset link will be sent to you.</p>
          )}
          <label className={styles.formLabel} htmlFor="userEmail">Email</label>
          <input className={styles.formInput} name="userEmail" type="text" {...inputEmail} autoComplete="email" />
        </div>
        <div className={styles.inputBlock}>
          <label className={styles.formLabel} htmlFor="userPassword">Password</label>
          <input className={styles.formInput} name="userPassword" type="password" {...password} autoComplete="password" />
        </div>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}
        <input className={styles.formSubmit} type="submit" value={loading ? 'Loading...' : 'Login'} onClick={handleLogin} disabled={loading} /><br />
        <input className={styles.formSubmit} type="button" value={loading ? 'Loading...' : 'Send me a password reset link'} onClick={forgotten} disabled={loading} />
      </form>
    </>
  );
}

export default Login;