import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  className: string;
};

const MemberSearch: React.FC<Props> = ({className}) => {

  return (
    <div className={className}>
        <div>
            <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/dashboard/all">All</NavLink>
            <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/dashboard/active">Active</NavLink>
            <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/dashboard/lapsed">Lapsed</NavLink>
            <NavLink className={({ isActive }) => isActive ? 'active' : ''} to="/dashboard/archived">Archived</NavLink>
        </div>
        <input id="member-search"/>
        <ul>
            
        </ul>
    </div>
  );
}

export default MemberSearch;