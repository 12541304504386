import React from 'react';
import MemberSearch from './MemberSearch';
import Toolbar from './Toolbar';
import styles from './Dashboard.module.css';


const Dashboard = () => {
  return (
    <div className={styles.dashboardWrapper}>
      <MemberSearch className={styles.dashboardContent}/>
      <Toolbar className={styles.toolbar}/>
    </div>
  );
}

export default Dashboard;