// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App_pageContainer__H23-Q {\n    padding: 20px 80px;\n    overflow: visible;\n    position: relative;\n    max-width: 1000px;\n    margin: 0 auto;\n    font-family: \"myriad-pro\", sans-serif;\n}", "",{"version":3,"sources":["webpack://./src/App.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,qCAAqC;AACzC","sourcesContent":[".pageContainer {\n    padding: 20px 80px;\n    overflow: visible;\n    position: relative;\n    max-width: 1000px;\n    margin: 0 auto;\n    font-family: \"myriad-pro\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "App_pageContainer__H23-Q"
};
export default ___CSS_LOADER_EXPORT___;
