import React from "react";
import { NavLink } from "react-router-dom";
import styles from './Toolbar.module.css';

type Props = {
  className: string;
};

const Toolbar: React.FC<Props> = ({className}) => {

  return (
    <div className={className}>
        <NavLink className={styles.button} to="/add-member">Sign up member</NavLink>
        {/* <h3>Tools</h3>
        <NavLink className={styles.button} to="/bcc-list">Mailing list</NavLink>
        <NavLink className={styles.button} to="/tool-active-cards">Access cards</NavLink>
        <h3>Reports</h3>
        <NavLink className={styles.button} to="/report-data-integrity">Data integrity</NavLink>
        <NavLink className={styles.button} to="/report-inductions-members">Inductions by Member</NavLink>
        <NavLink className={styles.button} to="/report-inductions-machine">Inductions by Machine</NavLink>
        <NavLink className={styles.button} to="/report-member-growth">Member growth</NavLink>
        <NavLink className={styles.button} to="/report-last-used">Who used it last?</NavLink>
        <NavLink className={styles.button} to="/report-quarterly-stats">Quarterly stats</NavLink> */}
    </div>
  );
}

export default Toolbar;