import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { post } from '../../utils/api';

const PasswordReset = () => {
  const history = useNavigate();
  const { token } = useParams();
  const password = useFormInput('');
  const copyPassword = useFormInput('');
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const reset = () => {
    setLoading(true);
    if (password.value !== copyPassword.value){
      setError("Passwords do not match, please retype");
      setLoading(false);
      return;
    }
    if (password.value.length < 8){
      setError("Password must be at least 8 characters long");
      setLoading(false);
      return;
    }
    post(`users/pwreset`, { token: token, password: password }).then(response => {
      setLoading(false);
      history('/login');
    }).catch(error => {
      setLoading(false);
      if (error.response.status === 401) setError(error.response.data.message);
      else setError("Something went wrong. Please try again later.");
    });
  }

  return (
    <div>
      Reset Password<br /><br />
      <div>
        Enter new Password<br />
        <input type="password" {...password} autoComplete="new-password" />
      </div>
      <div style={{ marginTop: 10 }}>
        Retype Password<br />
        <input type="password" {...copyPassword} autoComplete="new-password" />
      </div>
      {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
      <input type="button" value={loading ? 'Loading...' : 'Set new Password'} onClick={reset} disabled={loading} />
    </div>
  );
}

const useFormInput = (initialValue:any) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e:any) => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default PasswordReset;