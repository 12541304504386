import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRoutes from './utils/PrivateRoutes';
import PublicRoutes from './utils/PublicRoutes';
import { getToken, removeUserSession, setUserSession } from './utils/common';

import Login from './pages/login/Login';
import Dashboard from './pages/dashboard/Dashboard';
import NotFound from './pages/NotFound';
import PasswordReset from './pages/login/PasswordReset';
import Header from './pages/header/Header';
import Signup from './pages/signup/Signup';
import styles from './App.module.css';
import { get } from './utils/api';

function App() {
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (!token) {
      return;
    }

    get(`verifyToken?token=${token}`).then(response => {
      setUserSession(token, response.data.user);
      setAuthLoading(false);
    }).catch(error => {
      removeUserSession();
      setAuthLoading(false);
    });
  }, []);

  if (authLoading && getToken()) {
    return <div className={styles.pageContainer}>Checking Authentication...</div>
  }

  return (
    <BrowserRouter>
      <Header />
      <div className={styles.pageContainer}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/pwreset/:token" element={<PasswordReset/> } />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/add-member" element={<Signup />} />
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
