import React from 'react';
import { getUser, removeUserSession } from '../../utils/common';
import styles from './Header.module.css';
import logo from '../../images/logo.png';
import { NavLink, useNavigate } from 'react-router-dom';

const Dashboard = () => {

  const history = useNavigate();
  const user = getUser();

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    history('/login');
  }
  
  return (
    <header className={styles.header}>
      <div className={styles.toolbar}>
        {user ? (
            <>
              <NavLink to="/dashboard"><img src={logo} alt="RLAB" className={styles.logo}/></NavLink>
              <h2 className={styles.headerText}>MEMBER ADMIN</h2>
              <NavLink className={`${styles.link} ${styles.leftmostLink}`} to="/allevents">Events</NavLink>
              <NavLink className={styles.link} to="/">Dashboard</NavLink>
              <span className={styles.link}>{user?.name}</span>
              <NavLink className={`${styles.link} ${styles.signoutLink}`} to="/login" onClick={handleLogout}>sign out</NavLink>
          </>
        ) : (
          <NavLink to="/login"><img src={logo} alt="RLAB" className={styles.logo}/></NavLink>
        )}
      </div>
    </header>
  );
}

export default Dashboard;