import axios from "axios";
import { useState } from "react";

export type Token = string;

export type User = {
  userId: number,
  name: string,
  email: string,
  permissionId: number
};

// return the user data from the session storage, if there is also a token
function getUser(): User | undefined {
  const userStr = sessionStorage.getItem('user');
  if (userStr && getToken()) return JSON.parse(userStr);
  else return undefined;
}

// return the token from the session storage
function getToken() {
  return sessionStorage.getItem('token') || null;
}

// remove the token and user from the session storage
function removeUserSession() {
  axios.defaults.headers.common['authorization'] = undefined // for all requests
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}

// set the token and user from the session storage
function setUserSession(token: Token, user: User) {
  axios.defaults.headers.common['authorization'] = token // for all requests
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}

function useFormInput(initialValue:any) {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e:any) => {
      setValue(e.target.value);
  }
  return {
      value,
      onChange: handleChange
  }
}

export {
  getUser,
  getToken,
  removeUserSession,
  setUserSession,
  useFormInput,
}