// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.Dashboard_dashboardWrapper__jkyxZ {\n    display: flex;\n}\n\n.Dashboard_dashboardContent__GLS-9 {\n    flex: 3 1;\n    position: relative;\n}\n\n.Dashboard_toolbar__e5RsB {\n    flex: 1 1;\n    position: relative;\n    padding-left: 20px;\n}", "",{"version":3,"sources":["webpack://./src/pages/dashboard/Dashboard.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":["\n.dashboardWrapper {\n    display: flex;\n}\n\n.dashboardContent {\n    flex: 3;\n    position: relative;\n}\n\n.toolbar {\n    flex: 1;\n    position: relative;\n    padding-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboardWrapper": "Dashboard_dashboardWrapper__jkyxZ",
	"dashboardContent": "Dashboard_dashboardContent__GLS-9",
	"toolbar": "Dashboard_toolbar__e5RsB"
};
export default ___CSS_LOADER_EXPORT___;
