// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.Toolbar_button__twmRN:hover {\n    opacity: 0.8;\n    transition: opacity 0.5s;\n}\n\n.Toolbar_button__twmRN {\n    text-decoration: none;\n    color: #555;\n    font-weight: normal;\n    display: block;\n    padding: 8px 12px;\n    text-decoration: none;\n    overflow: hidden;\n    border-radius: 5px;\n    color: black;\n    margin: 0 10px 10px 0;\n    background-color: #fff;\n    transition: opacity 0.5s;\n    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.35);\n    cursor: pointer;\n    opacity: 1;\n    -webkit-user-select: none;\n            user-select: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/dashboard/Toolbar.module.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,mBAAmB;IACnB,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,qBAAqB;IACrB,sBAAsB;IACtB,wBAAwB;IACxB,+CAA+C;IAC/C,eAAe;IACf,UAAU;IACV,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":["\n.button:hover {\n    opacity: 0.8;\n    transition: opacity 0.5s;\n}\n\n.button {\n    text-decoration: none;\n    color: #555;\n    font-weight: normal;\n    display: block;\n    padding: 8px 12px;\n    text-decoration: none;\n    overflow: hidden;\n    border-radius: 5px;\n    color: black;\n    margin: 0 10px 10px 0;\n    background-color: #fff;\n    transition: opacity 0.5s;\n    box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.35);\n    cursor: pointer;\n    opacity: 1;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Toolbar_button__twmRN"
};
export default ___CSS_LOADER_EXPORT___;
